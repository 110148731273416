import { Environment } from './type';

export const environment: Environment = {
  name: 'staging',
  production: true,
  development: false,
  test: false,
  logEnabled: true,
  coverageTypes: ['jp', 'g'],
  consoleUrl: 'https://staging.console.soracom.io',
  announcekit: {
    widgetUrl: 'https://changelog.soracom.io/widgets/v2/4t7aMw', // SORACOM,
    embeddedWidgetUrl: 'https://changelog.soracom.io/widgets/v2/X9Ikg',
  },
  changelogUrl: 'https://changelog.soracom.io',
  apiUrl: {
    jp: 'https://jp-prod-standby.api.soracom.io',
    g: 'https://g-prod-standby.api.soracom.io',
  },
  authSSOUrl: 'https://staging-auth.soracom.io',
  lagoonConsoleUrl: {
    jp: 'https://jp-staging.lagoon.soracom.io',
    g: 'https://g-staging.lagoon.soracom.io',
  },
  lagoonV2ConsoleUrl: {
    jp: 'https://jp-v2-staging.lagoon.soracom.io',
    g: 'https://g-v2-staging.lagoon.soracom.io',
  },
  lagoonV3ConsoleUrl: {
    jp: 'https://jp-v3-staging.lagoon.soracom.io',
    g: 'https://g-v3-staging.lagoon.soracom.io',
  },
  mosaicConsoleUrl: 'https://console.mosaic.soracom.io/',
  stripePublishableKey: {
    jp: 'pk_live_Z0pA6othlrl9u4pagWYUNg9q',
    g: 'pk_live_XyoLuy5I4mZAw2oaMOtTzwJw00fwwtcEQw',
  },
  signupUrl: 'https://staging-auth.soracom.io/signup/console#',
  s3Urls: {
    billing: [
      'https://soracom-billing-fileoutput-g-prod.s3.eu-central-1.amazonaws.com',
      'https://soracom-billing-fileoutput-prod.s3.ap-northeast-1.amazonaws.com',

      // NOTE: 2018-S3-URL-change
      // At some point in 2018 it seems that the ap-northeast-1 S3 bucket URLs were changed to be
      // consistent with the naming of the EU ones. (. after s3, not -)
      // This broke this URL mapping feature. The old URLs are below, and still here "just in case"
      'https://soracom-billing-fileoutput-prod.s3-ap-northeast-1.amazonaws.com',
    ],
    invoice: ['https://soracom-invoice-g-prod.s3.eu-central-1.amazonaws.com'],
    stats: [
      'https://soracom-subscriber-stats-fileoutput-g-prod.s3.eu-central-1.amazonaws.com',
      'https://soracom-subscriber-stats-fileoutput-prod.s3.ap-northeast-1.amazonaws.com',

      // see 2018-S3-URL-change note above about the below URLs:
      'https://soracom-subscriber-stats-fileoutput-prod.s3-ap-northeast-1.amazonaws.com',
    ],
    subscribers: [
      'https://soracom-subscriber-fileoutput-prod.s3.ap-northeast-1.amazonaws.com',
      'https://soracom-subscriber-fileoutput-g-prod.s3.eu-central-1.amazonaws.com',
    ],
  },
  questUrl: 'https://staging.console.soracom.io/diagnostics',
  dataDogToken: 'puba977db7c338919e3d47cbde0f054aade',
  googleTagManagerId: undefined,
};
